import * as React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';

const NotFound = (props) => { 
    return (
        <article id="notfound" className="page-content">
            <SEO title="Page not found" />
            <header className="text-center">
                <h1>Page not found</h1>
            </header>
                
            <div className="page-content__body">            
                <div className="page-content__inner">   
                    <br />
                    <p className="text-center">Sorry, we couldn't find that page!</p>
                    <br />
                    <p className="text-center">
                        <Link to="/">Return to homepage</Link>
                    </p>
                    <br />
                    <br />
                </div>
            </div>
        </article>  
    );
};

export default NotFound;